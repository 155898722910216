import React from 'react';
import s from './RulesParliamentaryEthics.module.css';

const RulesParliamentaryEthics = ({ isActive }) => {

   return (
      <div className={`${s.tabPage} ${isActive === 'rulesParliamentaryEthics' && s.active}`}>
         <div className="text">
            <div className="pageTitle mt80">Правила депутатской этики</div>
            <div className='mt40'>Настоящие правила определяют моральные принципы, этические нормы и правила поведения, обязательные для депутата Совета депутатов  городского округа Химки.</div>

            <p>1. Общие положения</p>
            <p>1.1. Депутат Совета депутатов городского округа (далее по тексту – депутат), в своей деятельности должен руководствоваться интересами населения городского округа, в целях его социально - экономического и культурного развития.</p>
            <p>1.2. Недопустимо использование депутатом своего статуса в ущерб интересам  городского округа и его жителями.</p>
            <p>1.3. Депутат осуществляет свою деятельность в соответствии с Конституцией Российской Федерации, федеральными законами и законами Московской области, Уставом городского округа Химки, регламентом Совета депутатов и иными нормативными правовыми актами органов местного самоуправления городского округа.</p>
            <p>1.4. Депутат во всей своей деятельности, в том числе не связанной с выполнением депутатских полномочий, должен руководствоваться общепризнанными нормами морали и нравственности.</p>
            <p>1.5. Депутат должен воздерживаться от всякой деятельности и поступков, которые могут нанести ущерб авторитету депутата и органам  местного самоуправления городского округа.</p>
            <p>1.6. Депутат должен в равной мере охранять собственное достоинство и уважать достоинство других депутатов, должностных лиц и граждан</p>
            <p>2. Правила депутатской этики, относящиеся к деятельности депутата в Совете депутатов городского округа</p>
            <p>2.1. Взаимоотношения между депутатами строятся на основе равноправия.</p>
            <p>Депутат должен лояльно относиться к другим депутатам независимо от их социального статуса и политической ориентации.</p>
            <p>Депутаты должны строить свою работу на принципах свободного коллективного обсуждения и решения вопросов, уважения к многообразию мнений, не допускать конфликтов, искать пути преодоления разногласий среди депутатов.</p>
            <p>Депутат не должен навязывать свою позицию посредством угроз, ультиматумов и иных подобных действий.</p>
            <p>2.2. Депутат должен активно и конструктивно участвовать в работе Совета депутатов городского округа.</p>
            <p>2.3. Депутат должен присутствовать на всех заседаниях постоянных комиссий, Совета депутатов городского округа, членом которых он является.</p>
            <p>В случае отсутствия депутата на заседаниях Совета депутатов городского округа в течение 3-х заседаний, а постоянных комиссий более 4-х заседаний подряд без уважительных причин, вопрос о ненадлежащем исполнении депутатских обязанностей может быть вынесен на рассмотрение комиссии по вопросам законодательства, местного самоуправления и депутатской этике.</p>
            <p>2.4. В случае, если депутат имеет финансовую, либо иную личную заинтересованность в каком либо вопросе, который обсуждается в Совете депутатов городского округа в его присутствии, он должен немедленно сообщить об этом и воздержаться от участия в голосовании по данному вопросу.</p>
            <p>2.5. Депутаты должны подчиняться принятому ими Регламенту Совета депутатов городского округа, распоряжениям председательствующего на заседании Совета депутатов городского округа, касающимся поддержания порядка и соблюдения Регламента Совета депутатов городского округа.  Не опаздывать без уважительных причин на заседания Совета депутатов городского округа, депутатские слушания, заседания комиссий, рабочих групп, иные мероприятия Совета депутатов городского округа.
            </p>
            <p>Не допускаются  выступления депутата без предоставления ему слова, выступления не по повестке дня и не по существу вопроса повестки дня, выкрики, прерывания выступающих.</p>
            <p>2.6. Участвуя в заседаниях Совета депутатов городского округа, работе депутатских комиссий и рабочих групп, депутат должен проявлять вежливость, тактичность и уважение к председательствующему на заседании Совета депутатов городского округа, депутатам, сотрудникам администрации городского округа и иным лицам, присутствующим на заседании Совета депутатов городского округа.</p>
            <p>В соответствии с Регламентом Совета депутатов городского округа выступающий на заседании Совета депутатов городского округа депутат не должен использовать в своей речи грубые и некорректные выражения. В случае нарушения депутатом данного правила выступления на заседаниях Совета депутатов городского округа, к нему могут быть применены меры воздействия, предусмотренные Регламентом Совета депутатов городского округа.</p>
            <p>2.7. Комиссия по вопросам законодательства, местного самоуправления и депутатской этики может рассматривать вопрос о нарушении депутатом правил депутатской этики по собственной инициативе.</p>
            <p>Если поведение депутата, нарушившего правила  депутатской этики,  рекомендовано Советом депутатов городского округа к рассмотрению комиссией по вопросам законодательства, местного самоуправления  и депутатской этике, либо вопрос о поведении депутата рассматривается комиссией по вопросам законодательства, местного самоуправления и депутатской этике по собственной инициативе, к депутату могут быть применены меры морально-правового воздействия, предусмотренные Регламентом Совета депутатов городского округа  и п.п.  6.6. и  6.7. настоящих  Правил.</p>
            <p>2.8. В случае грубого нарушения правил депутатской этики,  Совет депутатов городского округа может решать вопрос об ответственности депутата без его предварительного рассмотрения комиссией по вопросам законодательства, местного самоуправления и депутатской этике. Признание нарушения правил депутатской этики, установленных настоящими правилами,  осуществляется непосредственно Советом депутатов городского округа.</p>
            <p>3. Правила депутатской этики во взаимоотношениях депутата  с  избирателями</p>
            <p>3.1. Депутат поддерживает постоянную связь с избирателями своего округа, ответственен перед ними и подотчетен им. Депутат также представляет интересы всех жителей городского округа.</p>
            <p>Взаимоотношения депутата с избирателями строятся на основе взаимного уважения и вежливости, внимательного отношения депутата к обращениям, жалобам, заявлениям граждан.</p>
            <p>3.2. Депутат принимает меры по обеспечению прав, свобод и законных интересов своих избирателей, рассматривает поступившие от них заявления, предложения, жалобы, способствует в пределах своих полномочий правильному и своевременному решению содержащихся  в них  вопросов.</p>
            <p>3.3. Депутат периодически отчитывается перед избирателями,  информирует о своей работе избирателей через средства массовой информации. Информация, предоставляемая депутатом избирателям, должна быть полной, достоверной, объективной.</p>
            <p>3.4. Депутат обязан осуществлять личный прием избирателей в избирательном округе по установленному  им  графику.</p>
            <p>4.  Правила депутатской этики во взаимоотношениях депутата с государственными  органами,  органами местного самоуправления, юридическими и физическими лицами</p>
            <p>4.1. Депутат не должен использовать в личных целях возможности, связанные со статусом депутата, во взаимоотношениях с государственными органами, органами местного самоуправления,  юридическими и физическими лицами.</p>
            <p>4.2. Депутат обязан использовать официальные бланки Совета депутатов городского округа только для официальных запросов и документов, необходимых для осуществления депутатских полномочий.</p>
            <p>4.3. Депутат не должен использовать помещение, телефоны, автотранспорт и другие материально-технические средства Совета депутатов городского округа в целях, не связанных с осуществлением депутатской деятельности.</p>
            <p>4.4. Депутат не может разглашать сведения, которые стали ему известны, в связи с осуществлением  депутатских полномочий, если эти сведения:</p>
            <ul>
               <li>составляют государственную, коммерческую или служебную тайну;</li>
               <li>связаны с личной или семейной жизнью граждан, с деловой репутацией и деятельностью физических и юридических лиц и доверены депутату на  условиях  их неразглашения.</li>
            </ul>
            <p>4.5. В случаях неосторожного разглашения сведений, связанных с личной или семейной жизнью граждан, с деловой репутацией и деятельностью физических и юридических лиц, доверенных депутату на  условиях их неразглашения, депутат обязан принести им  извинения.</p>
            <p>5. Этика публичных выступлений депутата</p>
            <p>5.1. Депутат, выступая на заседаниях Совета депутатов городского округа, депутатских комиссиях, в средствах массовой информации с различного рода публичными заявлениями, комментируя деятельность органов государственной власти, местного самоуправления, организаций и граждан, обязан использовать только достоверную проверенную информацию.</p>
            <p>5.2. Выступления депутата должны быть корректными, не порочащими и унижающими честь и достоинство граждан, должностных лиц, деловую репутацию юридических лиц.</p>
            <p>5.3. В случаях умышленного или неосторожного использования в публичных выступлениях или заявлениях депутата недостоверной, ложной информации, а также унижения чести и достоинства граждан, деловой репутации юридических лиц, и доказательства несостоятельности высказанного в выступлении или заявлении, депутат обязан публично признать недостоверность и некорректность своих высказываний, принести извинение тому гражданину, чьи честь и достоинство были затронуты,  или юридическому лицу,  деловая репутация которого была задета.</p>
            <p>Ответственность депутата, установленная  в тексте первого абзаца настоящего пункта, не освобождает его от ответственности в соответствии законодательством за предусмотренные настоящим разделом нарушения.</p>
            <p>6. Контроль соблюдения Правил депутатской этики и ответственность депутата за их нарушение</p>
            <p>6.1. Контроль соблюдения депутатами Правил депутатской этики осуществляет комиссия по вопросам законодательства,  местного самоуправления и депутатской этике.</p>
            <p>Председатель и члены комиссии по вопросам законодательства, местного самоуправления и депутатской этике обязаны предпринимать все необходимые меры по соблюдению депутатами Правил депутатской этики (сделать замечание, провести беседу и др.).</p>
            <p>6.2. Комиссия по вопросам законодательства, местного самоуправления  и депутатской этике может рассматривать вопрос о нарушении правил депутатской этики, как по собственной инициативе, так и по инициативе Совета депутатов  городского округа.</p>
            <p>6.3. С заявлением о рассмотрении вопроса о не этичности поведения депутата, в комиссию по вопросам законодательства, местного самоуправления и депутатской этике могут обращаться жители городского округа, должностные лица органов государственной власти, руководители организаций, общественных объединений.</p>
            <p>6.4. В случае обвинения депутата в совершении не этичных действий,  депутат вправе подать в комиссию по вопросам законодательства, местного самоуправлении и депутатской этике в письменном виде мотивированный протест, представить документы, дать устные пояснения по существу обвинения.</p>
            <p>6.5. В случае подачи необоснованной жалобы, порочащей честь, достоинство, деловую репутацию депутата, депутат вправе защищать свои права всеми способами, не запрещенными законом.</p>
            <p>В этом случае, при обращении депутата в органы государственной власти, органы местного самоуправления или суд с требованиями о защите чести, достоинства и деловой репутации, комиссия по вопросам законодательства, местного самоуправления и депутатской этике, администрация городского округа, оказывают содействие депутату в защите его чести, достоинства и деловой репутации,  в том числе путем оказания помощи в защите его интересов в суде.</p>
            <p>6.6. По результатам рассмотрения вопроса о нарушении правил депутатской этики, комиссия по вопросам законодательства, местного самоуправления и депутатской этике принимает решение о наличии или отсутствии нарушения депутатом правил депутатской этики.</p>
            <p>В случае установления факта нарушения депутатом настоящих правил, комиссия по вопросам законодательства, местного самоуправления  и депутатской этике может вынести решение с рекомендацией депутату строго следовать правилам депутатской этики и принести свои извинения стороне, в отношении которой были нарушены этические нормы. Информация о результатах рассмотрения вопроса о нарушении депутатом правил депутатской этики, доводится до депутатов Совета депутатов городского округа.</p>
            <p>6.7. В случае грубого нарушения правил депутатской этики, вопрос о применении к нарушившему их депутату меры воздействия, выносится комиссией по вопросам законодательства, местного самоуправления  и депутатской этики на рассмотрение Совета депутатов городского округа.</p>
            <p>Совет депутатов городского округа, может принять к депутату, нарушившему правила депутатской этики, одну из следующих мер воздействия:</p>
            <ul>
               <li>рекомендовать прекратить поведение, нарушающее правила депутатской этики, и настаивать на  принесении извинения стороне, в отношении которой были нарушены этические правила;</li>
               <li>довести факты нарушения депутатом норм депутатской этики до сведения избирателей округа через средства массовой информации;</li>
               <li>сообщить о фактах нарушения депутатом норм депутатской этики политической партии, выдвинувшей депутата в составе списка кандидато.</li>
            </ul>
         </div>
      </div>
   )
}
export default RulesParliamentaryEthics;