import React from 'react';
import s from './Documents.module.css';
import DocumentContainerDownload from '../../Components/DocumentContainerDownload';
import ContantContainerMain from '../../total/ContantContainerMain';

const Documents = (props) => {
   return (
      <div>
         <ContantContainerMain>
            <div className="text">
               <div className="mt40 pageTitle">Последние документы</div>

               <div className="mt40">
                  <DocumentContainerDownload title='Решение от 26.12.2023 № 28/1' text='О внесении изменений в решение Совета депутатов городского округа Химки Московской области от 29.11.2022 № 15/1 «О бюджете городского округа Химки Московской области на 2023 год и на плановый период 2024 и 2025 годов»' date='26.12.2023' />
               </div>
               <div className="mt32">
                  <DocumentContainerDownload title='Решение от 20.12.2023 № 27/3' text='О внесении изменений в решение Совета депутатов городского округа Химки Московской области от 26.12.2019 № 31/3 «Об утверждении Порядка поощрения муниципальной управленческой команды городского округа Химки Московской области, ответственной за достижение Московской областью значений (уровней) показателей, утвержденных Указом Президента Российской Федерации от 04.02.2021 № 68 «Об оценке эффективности деятельности высших должностных лиц (руководителей высших исполнительных органов государственной власти) субъектов Российской Федерации и деятельности органов исполнительной власти субъектов Российской Федерации»' date='20.12.2023' />
               </div>
               <div className="mt32">
                  <DocumentContainerDownload title='Решение от 20.12.2023 № 27/2' text='О принятии движимого имущества из государственной собственности Московской области в муниципальную собственность городского округа Химки Московской области' date='20.12.2023' />
               </div>
               <div className="mt32">
                  <DocumentContainerDownload title='Решение от 20.12.2023 № 27/1' text='О внесении изменения в решение Совета депутатов городского округа Химки Московской области от 27.11.2009 № 48/4 «Об установлении корректирующего коэффициента (Пкд) и коэффициента, учитывающего местоположение земельного участка (Км), для определения арендной платы при предоставлении в аренду земельных участков на территории городского округа Химки»' date='26.12.2023' />
               </div>
               <div className="mt32">
                  <DocumentContainerDownload title='Решение от 30.11.2023 № 26/10' text='О досрочном прекращении полномочий депутата Совета депутатов городского округа Химки Московской области  Кашникова Николая Александровича' date='30.11.2023' />
               </div>
               <div className="mt32">
                  <DocumentContainerDownload title='Решение от 30.11.2023 № 26/7' text='О внесении изменений в Правила благоустройства территории городского округа Химки Московской области, утвержденные решением Совета депутатов городского округа Химки Московской области от 02.12.2020 № 41/6' date='30.11.2023' />
               </div>
               <div className="mt32">
                  <DocumentContainerDownload title='Решение от 30.11.2023 № 26/6' text='Об утверждении Прогнозного плана приватизации имущества, находящегося в муниципальной собственности городского округа Химки Московской области, на 2024 год' date='30.11.2023' />
               </div>
               <div className="mt32">
                  <DocumentContainerDownload title='Решение от 30.11.2023 № 26/5' text='Об установлении величины порогового значения доходов и стоимости имущества в целях признания граждан, проживающих в городском округе Химки Московской области, малоимущими и предоставления им по договорам социального найма жилых помещений муниципального жилищного фонда в 2024 году' date='30.11.2023' />
               </div>
               <div className="mt32">
                  <DocumentContainerDownload title='Решение от 30.11.2023 № 26/4' text='О внесении изменений в Положение о муниципальном земельном контроле на территории городского округа Химки Московской области, утвержденное решением Совета депутатов городского округа Химки Московской области от 18.10.2021 № 02/7' date='30.11.2023' />
               </div>
               <div className="mt32">
                  <DocumentContainerDownload title='Решение от 30.11.2023 № 26/3' text='О принятии движимого имущества из государственной собственности Московской области в муниципальную собственность городского округа Химки Московской области' date='30.11.2023' />
               </div>
            </div>
         </ContantContainerMain>
      </div>
   )
}
export default Documents;